import React, {useState, useEffect } from 'react';
import { Typography, Box, Grid, List, ListItem, ListItemIcon, Link } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

import QuestionForm from './QuestionForm';
import { DefaultApi, Configuration } from 'quepasa-ai';

import ReactMarkdown from 'react-markdown';

const aic_token = "saas_demo_ai_cases:Jm4Y3jXgAdn62vuaf074b6T3PI/ADFScaZdCTm5/5gh1QbshpuHuQ1Q5SlN7Xp+X";
const description = [
	"Hello! Welcome to the QuePasa.ai bot!",
	"I’m here to help you explore how leading companies like ***Netflix***, ***Airbnb***, or ***DoorDash*** leverage AI to enhance their products and processes.",
	"Just type your query, and I’ll provide insights and case studies to inspire and inform.",
	"Let’s dive into the world of AI-driven innovation!"
];

const questions: string[] = [
	"Does Netflix use AI?",
	"Do big companies use RAG?",
	"Tell me about Uber RAG cases",
	"Tell me about RAG cases in Food tech",
	"How is AI being used at Duolingo?"
];

function FinBench(): JSX.Element {
	const [quepasa, setQuePasa] = useState<DefaultApi | null>(null);
	const [randomQuestions, setRandomQuestions] = useState<string[]>([]);
    const [closeDomains, setCloseDomains] = useState(false);

	const handleInteractionWithQuestionForm = () => {
		setCloseDomains(!closeDomains);
	};

	useEffect(() => {
		const apiInstance = new DefaultApi(new Configuration({ accessToken: aic_token }));
		setQuePasa(apiInstance);

		const getRandomQuestions = () => {
            const shuffled = questions.sort(() => 0.5 - Math.random());
            return shuffled.slice(0, 4);
        };
        setRandomQuestions(getRandomQuestions());

	}, []);

	return (
		<>
			<div className="adaptive-flex"  style={{marginTop: "30px"}}>
				<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 1 }}>
					<a href="https://www.quepasa.ai" target="_blank">
		          		<img src="/logo.png" alt="Logo" style={{ width: '150px', height: 'auto' }} />
					</a>
		        </Box>

				<Typography variant="h5" component="h2">
					AI real cases
				</Typography>
			</div>

			{description.map((line, index) => (
				<ReactMarkdown
					key={index}
					components={{
						a: ({ node, ...props }) => (
						  <a {...props} target="_blank" rel="noopener noreferrer">
							{props.children}
						  </a>
						),
					}}
				>
					{line}
				</ReactMarkdown>
			))}

			<div style={{ marginTop: '20px', marginBottom: '20px' }}>
				{quepasa && <QuestionForm domains={Object.keys([""])} questions={randomQuestions} url={true} onInteraction={handleInteractionWithQuestionForm} quepasa={quepasa} />}
			</div>
		</>
	);
}

export default FinBench;
