import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography, Box } from '@mui/material';
import { Button, Card, CardContent, TextField, Select, MenuItem, FormControl, InputLabel, Alert } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import KeyIcon from '@mui/icons-material/Key';

import '../App.css';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
	getAuth,
	GoogleAuthProvider,
	sendEmailVerification,
	signInWithEmailAndPassword,
	signInWithPopup,
	createUserWithEmailAndPassword,
	sendSignInLinkToEmail,
	deleteUser
} from 'firebase/auth';
import { auth } from '../firebaseConfig';

import { DefaultApi, Configuration } from 'quepasa-ai';

import {
	LOCAL_STORAGE_KEY_QUEPASA_ACCESS_TOKEN,
	LOCAL_STORAGE_KEY_USER_INFO,
	LOCAL_STORAGE_KEY_EMAIL_FOR_SIGN_IN,
	QUEPASA_TOKENS_ENDPOINT_URL
} from '../constants';

import { getAccessToken } from '../apiService';
import { useAuth } from './AuthProvider';

export default function LoginPage(): JSX.Element {
	const [step, setStep] = useState<"common" | "emailLogin" | "emailVerificationLinkSent">("common");
	const [email, setEmail] = useState('');
	const [emailError, setEmailError] = useState<string | null>(null);
	const [token, setToken] = useState('');
    const [tokenError, setTokenError] = useState<string | null>(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);

	const navigate = useNavigate();
	const { setQuepasaTokenInAuthProvider } = useAuth();

	const getQuepasaAccessTokenViaApi = async (fbtoken: string): Promise<string | null> => {
	    try {
	        const data = await getAccessToken(QUEPASA_TOKENS_ENDPOINT_URL, fbtoken);
	        console.log("Access token received:", data['token']);
	        return data['token'];
	    } catch (error) {
	        console.error("Failed to get access token:", error);
	        return null;
	    }
	};

	const handleTokenSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const tokenPattern = /^saas_[a-zA-Z0-9_]*:[a-zA-Z0-9\+-_\/=]*$/;

        if (tokenPattern.test(token)) {
            setToken('');
            setError(null);
			const checkResult = await checkQuepasaAccessToken(token);
			if (checkResult) {
				let userInfo = {
					uid: "",
					fireBaseAccessToken: "",
					displayName: token.split(':')[0],
					email: ""
				};
				localStorage.setItem(LOCAL_STORAGE_KEY_USER_INFO, JSON.stringify(userInfo));
				localStorage.setItem(LOCAL_STORAGE_KEY_QUEPASA_ACCESS_TOKEN, token);

            	setQuepasaTokenInAuthProvider(token);

				navigate('/');
			} else {
				localStorage.removeItem(LOCAL_STORAGE_KEY_QUEPASA_ACCESS_TOKEN);
			}
        } else {
            setError('Invalid token format. Please enter a token in the correct format: saas_xxxx:XXXXXXXXXXXXXXXXXXXXXXXXXX');
        }
    };

	useEffect(() => {
		const checkToken = async () => {
	        const storedToken = localStorage.getItem(LOCAL_STORAGE_KEY_QUEPASA_ACCESS_TOKEN);
	        if (storedToken) {
	            const checkResult = await checkQuepasaAccessToken(storedToken);
	            if (checkResult) {
	                navigate('/');
	            } else {
	                localStorage.removeItem(LOCAL_STORAGE_KEY_QUEPASA_ACCESS_TOKEN);
	            }
	        }
	    };

	    checkToken();
    }, []);

	const checkQuepasaAccessToken = async (quepasaAccessToken: string) => {
		try {
			const apiInstance = new DefaultApi(new Configuration({ accessToken: quepasaAccessToken }));
			let results = await apiInstance.listAllDocuments();
			setTokenError(null);
			return true;

		} catch (err) {
			console.log('Failed to check quepasa token.');
			console.log( err );
			setTokenError("Something went wrong. Check your token and enter it again!");
			return false;
		}
	}

	const sendVerificationLink = async (email: string) => {
		const actionCodeSettings = {
			url: window.location.origin + '/finishSignUp',
			//url: 'https://demo.quepasa.ai/finishSignUp',
			//url: 'http://localhost:3000/finishSignUp',
			handleCodeInApp: true,
		};

		try {
			await sendSignInLinkToEmail(auth, email, actionCodeSettings);
			localStorage.setItem(LOCAL_STORAGE_KEY_EMAIL_FOR_SIGN_IN, email);
			console.log("To continue click the link sent to:", email);
			setStep("emailVerificationLinkSent");
		} catch (error: any) {
			console.error(error.message);
		}
	}

	const handleEmailSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
		setLoading(true);

        try {
        	await sendVerificationLink(email);
            localStorage.setItem(LOCAL_STORAGE_KEY_USER_INFO, JSON.stringify({
                displayName: email,
                email: email
            }));

            setEmailError(null);
        } catch (error: any) {
            setEmailError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleGoogleLogin = async () => {
		setLoading(true);

        try {
            const provider = new GoogleAuthProvider();
            const result = await signInWithPopup(auth, provider);

			const user = result.user;
	   		const token = await user.getIdToken();

			localStorage.setItem(LOCAL_STORAGE_KEY_USER_INFO, JSON.stringify({
				uid: user.uid,
				fireBaseAccessToken: token,
				displayName: user.displayName,
				email: user.email
			}));

			setError(null);

			const quepasaAccessToken = await getQuepasaAccessTokenViaApi(token);

			if (quepasaAccessToken) {
				setTokenError(null);
				localStorage.setItem(LOCAL_STORAGE_KEY_QUEPASA_ACCESS_TOKEN, quepasaAccessToken);

				navigate('/');
			} else {
    			setTokenError("Failed to get Quepasa access token.");
			}

        } catch (authError: any) {
            setError(authError.message);
        } finally {
            setLoading(false);
        }
    };

	return (
		<div id="login">
			<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 1 }}>
				<a href="https://www.quepasa.ai" target="_blank">
					<img src="logo.png" alt="Logo" style={{ width: '150px', height: 'auto' }} />
				</a>
			</Box>

			<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 1 }}>
		        <Card sx={{marginTop: "40px", marginBottom: "40px", maxWidth: "500px"}}>
		            <CardContent>
		                {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
						{loading && <Alert severity="info" sx={{ mb: 2 }}>Loading...</Alert>}

						{(
			                <Button
			                    variant="contained"
			                    color="inherit"
								startIcon={<img src="/google.svg" alt="Google Icon" style={{ width: 16, height: 16, marginBottom: "2px" }} />}
			                    fullWidth
			                    sx={{ mb: 2 }}
			                    onClick={handleGoogleLogin}
								disabled={loading}
			                >
			                    Login with Google
			                </Button>
						)}

		                {step === "common" && (
							 <form onSubmit={handleEmailSubmit}>
								 <Typography variant="subtitle1" component="h3">
	 								Or enter your personal or work email:
	 							</Typography>

							 	<TextField
			                        label="xxxxx@xxxx.xx"
			                        variant="outlined"
			                        fullWidth
			                        margin="normal"
			                        value={email}
			                        onChange={(e) => setEmail(e.target.value)}
									disabled={loading}
			                    />
			                    <Button
									type="submit"
									variant="contained"
									color="primary"
									startIcon={<EmailIcon />}
									fullWidth
									disabled={loading}
								>
			                        Continue with email
			                    </Button>
			                </form>
						)}

						{step === "emailVerificationLinkSent" && (
							<div style={{textAlign: "center"}}>
								<EmailIcon />
								<Typography variant="subtitle1" component="h3">
									To continue login with email click the link sent to:<br/>{email}
								</Typography>
							</div>
                        )}

						<form onSubmit={handleTokenSubmit}>
							<Typography variant="subtitle1" component="h3" gutterBottom sx={{ mt: 2 }}>
								Or enter your private token:
							</Typography>

							{tokenError && <Alert severity="error" sx={{ mb: 2 }}>{tokenError}</Alert>}

		                    <TextField
		                        label="saas_xxxx:XXXXXXXXXXXXXXXXXXXXXXX"
		                        variant="outlined"
		                        fullWidth
		                        margin="normal"
		                        value={token}
		                        onChange={(e) => setToken(e.target.value.trim())}
								disabled={loading}
		                    />
		                    <Button type="submit" variant="contained" color="inherit" startIcon={<KeyIcon />} fullWidth>
		                        Submit Token
		                    </Button>
		                </form>

		            </CardContent>
		        </Card>
			</Box>
		</div>
    );
};
