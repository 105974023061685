import React from 'react';
import { Box, Typography, Container, IconButton  } from '@mui/material';
import Divider from '@mui/material/Divider';

import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { FaDiscord } from 'react-icons/fa';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer: React.FC = () => {
  return (
    <Box component="footer" sx={{ py: 3, mt: 'auto', backgroundColor: '#f0f0f0', textAlign: 'center', height: "100%" }}>
      <Container maxWidth="lg">
	  	<div className="footer-adaptive-flex"  style={{marginTop: "30px", justifyContent: "space-between"}}>
			<Box sx={{ mb: 1, ml: 1, mr: 2 }}>
				<a href="https://quepasa.ai" target="_blank">
	          		<img src="/logo.png" alt="Logo" style={{ width: '150px', height: 'auto' }} />
				</a>
	        </Box>

		  	<div style={{marginBottom: "16px"}}>
				<Typography component="span" variant="body1" color="textPrimary" sx={{ fontWeight: 600 }}>
					<a href="https://quepasa.ai/#Pricing" target="_blank" style={{ marginLeft: "10px", marginRight: "10px", color: 'inherit', textDecoration: 'none' }}>
						Pricing
					</a>
				</Typography>

				<Typography component="span" variant="body1" color="textPrimary" sx={{ fontWeight: 600 }}>
					<a href="https://quepasa.ai/blog" target="_blank" style={{ marginLeft: "10px", marginRight: "10px", color: 'inherit', textDecoration: 'none' }}>
						Blog
					</a>
				</Typography>

				<Typography component="span" variant="body1" color="textPrimary" sx={{ fontWeight: 600 }}>
					<a href="https://docs.quepasa.ai" target="_blank" style={{ marginLeft: "10px", marginRight: "10px", color: 'inherit', textDecoration: 'none' }}>
						Docs
					</a>
				</Typography>

				<Typography component="span" variant="body1" color="textPrimary" sx={{ fontWeight: 600 }}>
					<a href="mailto:hello@quepasa.ai" target="_blank" style={{ marginLeft: "10px", marginRight: "10px", color: 'inherit', textDecoration: 'none' }}>
						Contact
					</a>
				</Typography>
			</div>

			<Box sx={{ marginBottom: '16px' }}>
				<IconButton href="https://linkedin.com/company/99889421" target="_blank" aria-label="LinkedIn">
					<LinkedInIcon />
				</IconButton>
                <IconButton href="https://facebook.com/profile.php?id=100093552229139" target="_blank" aria-label="Facebook">
                    <FacebookIcon />
                </IconButton>
				<IconButton href="https://discord.gg/M9RB4cRDAt" target="_blank" aria-label="Discord">
                      <FaDiscord style={{ fontSize: '24px' }} />
                </IconButton>
            </Box>
		</div>

		<Divider sx={{ my: 6, mx: 0 }} />

		<div className="footer-adaptive-flex" style={{marginBottom: "30px"}}>
	        <Typography variant="body2" color="textSecondary" sx={{marginLeft: "15px", marginRight: "15px"}}>
	          Copyright&nbsp;&copy;&nbsp;{new Date().getFullYear()},&nbsp;QuePasa.ai. All&nbsp;rights&nbsp;reserved.
	        </Typography>

			<div style={{ marginBottom: "4px"}}>
				<Typography component="span" variant="body2" color="textSecondary">
					<a
						href="https://quepasa.ai/terms-and-condition" target="_blank"
						style={{ marginLeft: "10px", marginRight: "10px", color: 'inherit', textDecoration: 'underline', textDecorationColor: 'inherit' }}>
						Terms of Service
					</a>
				</Typography>

				<Typography component="span" variant="body2" color="textSecondary">
					<a
						href="https://quepasa.ai/privacy-policy" target="_blank"
						style={{ marginLeft: "10px", marginRight: "10px", color: 'inherit', textDecoration: 'underline', textDecorationColor: 'inherit' }}>
						Privacy Policy
					</a>
				</Typography>
			</div>
		</div>
      </Container>
    </Box>
  );
};

export default Footer;
