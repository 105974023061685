import React, {useState, useEffect, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import {
	Typography, Tooltip,
	Box,
	Button, IconButton,
	Menu, MenuItem,
	Dialog, DialogTitle, DialogContent, DialogActions,
	TextField, Alert, CircularProgress
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import FileUpload from './FileUpload';
import QuestionForm from './QuestionForm';

import { signOut } from 'firebase/auth';
import { auth } from '../firebaseConfig';

import { DefaultApi, Configuration } from 'quepasa-ai';

import {
	LOCAL_STORAGE_KEY_QUEPASA_ACCESS_TOKEN,
	LOCAL_STORAGE_KEY_USER_INFO,
} from '../constants';

interface FileData {
	name: string;
	fileState: string; // 'loading', 'error', 'indexing', 'loaded', 'deleting'
}

interface Domain {
	name: string;
	children: Record<string, FileData>;
}

function HomePage(): JSX.Element {
	const [domains, setDomains] = useState<Record<string, Domain>>({ 'default': { name: 'default', children: {} } });
	const [closeDomains, setCloseDomains] = useState(false);
	const [quepasaAccessToken, setQuepasaAccessToken] = useState<string | null>(null);
    const [quepasa, setQuePasa] = useState<DefaultApi | null>(null);
	const [authError, setAuthError] = useState<string | null>(null);
	const [openTokenPopup, setOpenTokenPopup] = useState(false);
	const [telegramToken, setTelegramToken] = useState<string>('');
	const [telegramStartName, setTelegramStartName] = useState<string>('Bot info');
	const [telegramStartMessage, setTelegramStartMessage] = useState<string>('Hello, this is bot for my data');
	const [telegramAskName, setTelegramAskName] = useState<string>('Ask a question');
	const [telegramAskMessage, setTelegramAskMessage] = useState<string>('Ask any question');
	const [openTelegramPopup, setOpenTelegramPopup] = useState(false);
	const [telegramStatus, setTelegramStatus] = useState<string>("default");	//"default", "loading", "created"
	const [telegramTokenError, setTelegramTokenError] = useState<string>('');
	const [copyTokenMessage, setCopyTokenMessage] = useState('Copy token');

	const handleDomainsUpdate = (newDomains: Record<string, Domain>) => {
		setDomains(newDomains);
	};

	const handleTokenChange = (token: string) => {
        setQuepasaAccessToken(token);
        localStorage.setItem(LOCAL_STORAGE_KEY_QUEPASA_ACCESS_TOKEN, token);
    };

	const handleTelegramTokenChange = (event: ChangeEvent<HTMLInputElement>) => {
        setTelegramToken(event.target.value);
    };

	const handleTelegramStartNameChange = (event: ChangeEvent<HTMLInputElement>) => {
		setTelegramStartName(event.target.value);
	};

	const handleTelegramStartMessageChange = (event: ChangeEvent<HTMLInputElement>) => {
		setTelegramStartMessage(event.target.value);
	};

	const handleTelegramAskNameChange = (event: ChangeEvent<HTMLInputElement>) => {
		setTelegramAskName(event.target.value);
	};

	const handleTelegramAskMessageChange = (event: ChangeEvent<HTMLInputElement>) => {
		setTelegramAskMessage(event.target.value);
	};

	const handleCreateTelegramBotSubmit = async (event: React.FormEvent) => {
		event.preventDefault();
		setTelegramStatus("loading");

		if (!telegramToken.trim()) {
			setTelegramTokenError("This field is required");
		}

		let telegramObj = {
			"token": telegramToken,
			"start": {
				"name": !telegramStartName.trim() ? "Bot info" : telegramStartName,
				"message": !telegramStartMessage.trim() ? "Hello, this is bot for my data" : telegramStartMessage
			},
			"ask": {
				"name": !telegramAskName.trim() ? "Ask a question" : telegramAskName,
				"message": !telegramAskMessage.trim() ? "Ask any question" : telegramAskMessage
			}
		};

		if (quepasa) {
			try {
				let result = await quepasa.setupTelegram(telegramObj);
				if (result != null && result.status == "OK") {
					setTelegramStatus("created");
				} else {
					setTelegramTokenError("Wrong Telegram Token");
					setTelegramStatus("default");
					console.log('Create Telegram Error!');
				}
			} catch (error) {
				setTelegramTokenError("Wrong Telegram Token");
				setTelegramStatus("default");
	        	console.log('Create Telegram Error: ', error);
	        }
		}
	}

	const navigate = useNavigate();

	useEffect(() => {
        const storedToken = localStorage.getItem(LOCAL_STORAGE_KEY_QUEPASA_ACCESS_TOKEN);
        if (storedToken) {
            setQuepasaAccessToken(storedToken);
        }
    }, []);

    useEffect(() => {
        if (quepasaAccessToken) {
            const apiInstance = new DefaultApi(new Configuration({ accessToken: quepasaAccessToken }));
            setQuePasa(apiInstance);
        }
    }, [quepasaAccessToken]);

    const handleInteractionWithQuestionForm = () => {
        setCloseDomains(!closeDomains);
    };

	//Settings
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleSettingsClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleLogout = async () => {
	    try {
	        await signOut(auth);
	        console.log("User signed out successfully");
			setQuepasaAccessToken(null);
	        localStorage.removeItem(LOCAL_STORAGE_KEY_USER_INFO);
	        localStorage.removeItem(LOCAL_STORAGE_KEY_QUEPASA_ACCESS_TOKEN);

			setQuePasa(null);
			handleMenuClose();
			navigate('/login');
	    } catch (error) {
	        console.error("Sign out error:", error);
	    }
	};

	const handleCopyToken = async () => {
      try {
		if (quepasaAccessToken) {
        	await navigator.clipboard.writeText(quepasaAccessToken);
        	setCopyTokenMessage('Your token is copied to the clipboard');
			setTimeout(() => setCopyTokenMessage('Copy token'), 2000);
		}
      } catch (error) {
        console.error('Copy error: ', error);
      }
    };

	//
	const totalFilesCount = Object.values(domains).reduce((count, domain) => {
	    const loadedChildrenCount = Object.values(domain.children).filter(child => child.fileState === 'loaded').length;
	    return count + loadedChildrenCount;
	}, 0);

	const userInfo = localStorage.getItem(LOCAL_STORAGE_KEY_USER_INFO);
	const displayName = userInfo ? JSON.parse(userInfo)['displayName']
						: (quepasaAccessToken ? quepasaAccessToken.split(':')[0] : "");

	return (
		<>
			<div className="adaptive-flex"  style={{marginTop: "30px"}}>
				<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 1 }}>
					<a href="https://www.quepasa.ai" target="_blank">
		          		<img src="/logo.png" alt="Logo" style={{ width: '150px', height: 'auto' }} />
					</a>
		        </Box>

				<div style={{position: "relative", display: "flex", flexDirection: "row"}}>
					{quepasaAccessToken &&
						<Typography variant="h5" component="h2" gutterBottom sx={{paddingRight: "44px"}}>
							{displayName}
						</Typography>
					}

					<IconButton
						onClick={handleSettingsClick}
						sx={{
							position: "absolute",
							top: -8,
							right: -8
						}}>
						<SettingsIcon sx={{ fontSize: 32 }}/>
					</IconButton>
					<Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
						<MenuItem onClick={handleLogout}>Log out</MenuItem>
						<MenuItem onClick={() => {setOpenTokenPopup(true)}}>My token</MenuItem>
						<MenuItem onClick={() => {setOpenTelegramPopup(true); setTelegramStatus("default")}}>Telegram Bot</MenuItem>
						<Dialog open={openTokenPopup} onClose={() => {setOpenTokenPopup(false)}}>
					        <DialogTitle>
								My token
								<Box style={{position: 'absolute', right: 8, top: 8}}>
									<Tooltip
										title={copyTokenMessage}
										arrow
									>
										<IconButton
											onClick={handleCopyToken}
							            >
							            	<ContentCopyIcon />
							            </IconButton>
							        </Tooltip>

									<IconButton
									  aria-label="close"
									  onClick={() => {setOpenTokenPopup(false)}}
									>
									  <CloseIcon />
									</IconButton>
								</Box>

							</DialogTitle>
					        <DialogContent><Typography variant="body1">{quepasaAccessToken}</Typography></DialogContent>
				      	</Dialog>
						<Dialog
							sx={{
								'& .MuiDialog-paper': {
								 width: { xs: '300px', sm: '400px', md: '500px' },
								 height: 'auto',
								 borderRadius: '8px',
								 padding: '16px',
								}
							}}
							open={openTelegramPopup}
							onClose={() => {setOpenTelegramPopup(false)}}>
							<DialogTitle>
								Connect Quepasa to your Telegram Bot
								<Box style={{position: 'absolute', right: 8, top: 8}}>
									<IconButton
									  aria-label="close"
									  onClick={() => {setOpenTelegramPopup(false)}}
									>
									  <CloseIcon />
									</IconButton>
								</Box>
								{telegramStatus == "default" &&
									<Typography variant="body1">
										First create a bot in telegram via <a href="https://t.me/BotFather" target="_blank">@BotFather</a> and copy token
									</Typography>
								}
								{telegramStatus == "created" &&
									<Typography variant="body1">
										Now you can use your AI telegram bot!
									</Typography>
								}
							</DialogTitle>
							<DialogContent sx={{padding: "8px", height: 'auto'}}>
								{telegramStatus === "loading" &&
							        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '200px' }}>
							            <CircularProgress />
							            <Typography variant="body1" sx={{ marginTop: '16px' }}>Creating your bot, please wait...</Typography>
							        </Box>
							    }
								{telegramStatus == "default" &&
									<>
										<TextField
											name="telegramStartName" label="Telegram Start Name" value={telegramStartName}
											onChange={handleTelegramStartNameChange}
											margin="normal" fullWidth
										/>
										<TextField
											name="telegramStartMessage" label="Telegram Start Message" value={telegramStartMessage}
											onChange={handleTelegramStartMessageChange}
											margin="normal" fullWidth
										/>
										<TextField
											name="telegramAskName" label="Telegram Ask Name" value={telegramAskName}
											onChange={handleTelegramAskNameChange}
											margin="normal" fullWidth
										/>
										<TextField
										name="telegramAskMessage" label="Telegram Ask Message" value={telegramAskMessage}
										onChange={handleTelegramAskMessageChange}
											margin="normal" fullWidth
										/>
										{telegramTokenError && <Alert severity="error">{telegramTokenError}</Alert>}
										<TextField
											name="telegramToken" label="Telegram Token" value={telegramToken}
											onChange={handleTelegramTokenChange}
											margin="normal" fullWidth
										/>
									</>
								}
							</DialogContent>
							 {telegramStatus == "default" &&
								 <DialogActions>
									 <Button onClick={handleCreateTelegramBotSubmit} variant="contained" color="primary">
										 Submit
									 </Button>
								 </DialogActions>
							 }
						</Dialog>
					</Menu>
				</div>
			</div>

			<div style={{ marginBottom: '20px' }}>
				{quepasa && <FileUpload onDomainsUpdate={handleDomainsUpdate} onAuthError={(authError) => {setAuthError(authError)}} closeDomains={closeDomains} quepasa={quepasa} />}
			</div>
			<div style={{ marginBottom: '20px' }}>
				{(quepasa && totalFilesCount > 0) &&
				<QuestionForm domains={Object.keys(domains)} questions={[]} url={false} onInteraction={handleInteractionWithQuestionForm} quepasa={quepasa} />
			}
			</div>
		</>
	);
}

export default HomePage;
