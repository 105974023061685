import React, { useState, ChangeEvent, FormEvent } from 'react';
import {
	Card, CardContent,
	Typography,
	FormControl, TextField,
	InputAdornment,	IconButton,
	Select,	MenuItem,
	InputLabel,
	Alert,
	ToggleButton, ToggleButtonGroup, Button,
	Grid,
	CircularProgress
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { SelectChangeEvent } from '@mui/material/Select';
import { DefaultApi, Configuration } from 'quepasa-ai';

import ReactMarkdown from 'react-markdown';

import '../App.css';

interface QuestionFormProps {
    onInteraction: () => void;
	questions: string[];
	url: boolean;
    domains: string[];
	quepasa: DefaultApi;
}

const LLMlist = [
	'replicate:meta-llama-3-8b-instruct',
	'replicate:meta-llama-3-70b-instruct',
	'gpt-4o-mini-2024-07-18',
	'gpt-3.5-turbo-16k-0613',
	'mistral:ministral-3b-latest',
	'mistral:mistral-large-2402',
	'anthropic:claude-3-5-haiku-20241022',
	'anthropic:claude-3-5-sonnet-20240620'
];
const defaultLLM = 'anthropic:claude-3-5-sonnet-20240620'

const answerSizeList: { [key: string]: number } = {
	'450 token': 450,
	'600 token': 600,
	'900 token': 900,
	'1200 token': 1200,
	'2k token': 2000,
	//'3k token': 3000,
	//'4k token': 4000
};
const defaultAnswerSize = '900 token'

const contextSizeList: { [key: string]: number } = {
	'2k token': 2000,
	'4k token': 4000,
	'6k token': 6000,
	'8k token': 8000,
	'10k token': 10000,
	'12k token': 12000
};
const defaultContextSize = '8k token'

const relevanceWeightsOptions = [
	{ text: 1.0, semantic: 0.0 },
	{ text: 0.8, semantic: 0.2 },
	{ text: 0.5, semantic: 0.5 },
	{ text: 0.2, semantic: 0.8 },
	{ text: 0.0, semantic: 1.0 },
];
const defualtRelevanceWeights = { text: 0.2, semantic: 0.8 };

const defaultPrompt = `
You're a bot-assistant that answers the questions.
When answering the question, use the following rules:
- always answer in {{LANGUAGE}} language;
- use ONLY the information from the sources below;
- answer briefly in just a few sentences, strictly in accordance with the sources, and do not make any assumptions;
- reference the source if you use it in the answer, e.g. [#1] or [#2][#4];
- if there is no information on the question in the sources: say that you can't find the answer and ask the user to try to reformulate the question.

Sources:
{{SOURCES}}
`;

export default function QuestionForm({ onInteraction, domains, quepasa, questions, url }: QuestionFormProps): JSX.Element {
	const [question, setQuestion] = useState<string>('');
	const [mode, setMode] = useState<string>('default');
	const [selectedDomain, setSelectedDomain] = useState<string>('all');
	const [selectedLLM, setSelectedLLM] = useState<string>(defaultLLM);
	const [selectedAnswerSize, setSelectedAnswerSize] = useState<string>(defaultAnswerSize);
	const [selectedContextSize, setSelectedContextSize] = useState<string>(defaultContextSize);
	const [promptMode, setPromptMode] = useState<string>('default');
	const [selectedPrompt, setSelectedPrompt] = useState<string>(defaultPrompt);
	const [documentRelevance, setDocumentRelevance] = useState<{ text: number, semantic: number } | null>(defualtRelevanceWeights);
	const [chunkRelevance, setChunkRelevance] = useState<{ text: number, semantic: number } | null>(defualtRelevanceWeights);
	const [answer, setAnswer] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);

	const handleFocus = () => {
		onInteraction();
	};

	const handleQuestionChange = (event: ChangeEvent<HTMLInputElement>) => {
		setQuestion(event.target.value);
	};

	const handleDomainChange = (event: SelectChangeEvent<string>) => {
        setSelectedDomain(event.target.value as string);
    };

	const handleLLMChange = (event: SelectChangeEvent<string>) => {
        setSelectedLLM(event.target.value as string);
    };

	const handleAnswerSizeChange = (event: SelectChangeEvent<string>) => {
        setSelectedAnswerSize(event.target.value as string);
    };

	const handleContextSizeChange = (event: SelectChangeEvent<string>) => {
        setSelectedContextSize(event.target.value as string);
    };

	const handleDocumentRelevanceChange = (event: SelectChangeEvent<string>) => {
	    setDocumentRelevance(JSON.parse(event.target.value));
	};

	const handleChunkRelevanceChange = (event: SelectChangeEvent<string>) => {
	    setChunkRelevance(JSON.parse(event.target.value));
	};

	const handleModeChange = (event: React.MouseEvent<HTMLElement>, newMode: string) => {
        if (newMode !== null) {
            setMode(newMode);
        }
    };

	const handlePromptModeChange = (event: React.MouseEvent<HTMLElement>, newMode: string) => {
        if (newMode !== null) {
            setPromptMode(newMode);
        }
    };

	const handlePromptChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSelectedPrompt(event.target.value);
    };

	const askQuestion = async (question: string) => {
		setIsLoading(true);
		setAnswer("");
		setError(null);

		try {
			let questionObj: any = { question: question };

			if (promptMode === 'custom') {
				if ( selectedPrompt != "" && selectedPrompt != null ) {
					questionObj.prompt = selectedPrompt;
				}
			}

			if (mode === 'custom') {
				if (selectedDomain != "all") {
					questionObj.domain = selectedDomain;
				}
				if (selectedLLM != "" && selectedLLM != defaultLLM) {
					questionObj.llm = selectedLLM;
				}
				if (selectedAnswerSize != null && selectedAnswerSize != "" && selectedAnswerSize != defaultAnswerSize) {
					questionObj.answer_prompt_size = answerSizeList[selectedAnswerSize];
				}
				if (selectedContextSize != null && selectedContextSize != "" && selectedContextSize != defaultContextSize) {
					questionObj.prompt_total_size = contextSizeList[selectedContextSize];
				}
				if (documentRelevance != null && !(documentRelevance.text == defualtRelevanceWeights.text && documentRelevance.semantic == defualtRelevanceWeights.semantic)) {
					questionObj.document_relevance_weights = documentRelevance;
				}
				if (chunkRelevance != null && !(chunkRelevance.text == defualtRelevanceWeights.text && chunkRelevance.semantic == defualtRelevanceWeights.semantic)) {
					questionObj.chunk_relevance_weights = chunkRelevance;
				}

				console.log(questionObj)
			}

			let result = await quepasa.retrieveAnswer(questionObj);
			setIsLoading(false);

			if (result != null) {
				let answer = result.answer || 'No answer received.';
				const regex = /\[(\d+)\]/g;

				/*if (url) {
					answer = answer.replace(regex, (match, index) => {
		    			const idx = parseInt(index, 10);
						return result.links && result.links[idx] !== undefined ? ('[***[' + result.links[idx].title + ']***](' + result.links[idx].url + ')') : match;
					});
				}
				else {
					answer = answer.replace(regex, (match, index) => {
						const idx = parseInt(index, 10);
						return result.links && result.links[idx] !== undefined ? ('***[' + result.links[idx].title + ']***') : match;
					});
				}*/

				let linksWithoutDouble: string[] = [];
				for (let index in result.links) {
					const title = result.links[index].title;
					if (title !== undefined && !linksWithoutDouble.includes(title)) {
						linksWithoutDouble.push(title);
					}
				}

				if (url) {
					answer = answer.replace(regex, (match, index) => {
						if (result.links) {
							const title = result.links[index].title;
							if (title !== undefined) {
								let idx = linksWithoutDouble.indexOf(title) + 1; //Object.keys(result.links).indexOf(index) + 1;
								return ('[***[' + idx + ']***](' + result.links[index].url + ')');
							} else {
								return match;
							}
						}
						return match;
					});
				} else {
					answer = answer.replace(regex, (match, index) => {
						if (result.links) {
							const title = result.links[index].title;
							if (title !== undefined) {
								let idx = linksWithoutDouble.indexOf(title) + 1; //Object.keys(result.links).indexOf(index) + 1;
								return ('***[' + idx + ']***');
							} else {
								return match;
							}
						}
						return match;
					});
				}

				if (result.links !== undefined && Object.keys(result.links).length > 0) {
					answer += "  \n";
				}

				let usedIndex: number[] = [];
				for (let index in result.links) {
					const title = result.links[index].title;
					if (title !== undefined) {
						const idx = linksWithoutDouble.indexOf(title) + 1; //Object.keys(result.links).indexOf(index) + 1;
						if (usedIndex.includes(idx)) continue;
						usedIndex.push(idx);
						if (url) {
							answer += "  \n" + "[***[" + idx + "]*** - " + "***" + title + "***](" + result.links[index].url + ")";
						}
						else {
							answer += "  \n" + "***[" + idx + "] - " + title + "***";
						}
					}
				}
				setAnswer( answer );
			}
		} catch (err) {
			console.error("Failed to get an answer.");
			setError('Failed to get an answer.');
			setIsLoading(false);
		}
	};

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();

		if (!question) {
			setError('Please enter a question.');
			return;
		} else {
			await askQuestion(question);
		}
	};

	const handleQuestionClick = (question: string) => {
		setQuestion(question);
		console.log(question);
		askQuestion(question);
	};

	return (
		<Card>
            <CardContent>
                <Typography variant="h6" component="h2" gutterBottom>
                    Ask Your Question
                </Typography>

				<form onSubmit={handleSubmit}>
					<div>
						<ToggleButtonGroup
	                        value={promptMode}
	                        exclusive
	                        onChange={handlePromptModeChange}
							sx={{
								width: {
									xs: '100%', sm: '500px', md: '500px'
								}
							}}
							onFocus={handleFocus}
	                    >
	                        <ToggleButton value="default" style={{width: '100%'}}>Default prompt</ToggleButton>
	                        <ToggleButton value="custom" style={{width: '100%'}}>Customize prompt</ToggleButton>
	                    </ToggleButtonGroup>

						{promptMode == 'custom' && (
							<div style={{marginTop: '10px'}}>
								<Typography variant="body2" style={{marginBottom: '10px'}}>
									{"Use placeholders {{LANGUAGE}} for question language and {{SOURCES}} for RAG context"}
								</Typography>

								<FormControl fullWidth onFocus={handleFocus}>
									<TextField
										label="Custom Prompt Example"
										variant="outlined"
										value={selectedPrompt}
										onChange={handlePromptChange}
										multiline
									/>
								</FormControl>
							</div>
						)}
					</div>

					<div>
						<ToggleButtonGroup
	                        value={mode}
	                        exclusive
	                        onChange={handleModeChange}
	                        style={{ marginTop: '20px', marginBottom: '20px' }}
							sx={{
							  width: {
								 xs: '100%', sm: '500px', md: '500px'
							  },
							}}
							onFocus={handleFocus}
	                    >
	                        <ToggleButton value="default" style={{width: '100%'}}>Default parameters</ToggleButton>
	                        <ToggleButton value="custom" style={{width: '100%'}}>Customize parameters</ToggleButton>
	                    </ToggleButtonGroup>
					</div>

					{mode === 'custom' && (
						<>
							<div style={{ marginBottom: "10px" }}>
								<Typography variant="body2">
									For details on using custom parameters, see the <a target="blank" href="https://docs.quepasa.ai/reference#tag/default/POST/retrieve/answer">documentation</a>
								</Typography>
							</div>

							<div className="adaptive-flex">
								<FormControl
									style={{ marginRight: '10px', marginTop: '10px', marginBottom: '10px' }}
									sx={{
										width: {
											xs: '100%', sm: 'calc(33% - 10px)', md: 'calc(33% - 10px)'
										},
									}}
								>
									<InputLabel shrink={true} sx={{backgroundColor: 'white', px: 1}}>Choose Context Size</InputLabel>
									<Select value={selectedContextSize} onChange={handleContextSizeChange} notched label="Choose LLM" >
										{Object.keys(contextSizeList).map((size) => (
											<MenuItem key={size} value={size}>
												{((size == defaultContextSize) ? "default: " : "") + size}
											</MenuItem>
										))}
									</Select>
								</FormControl>

								<FormControl
									style={{ marginRight: '10px', marginTop: '10px', marginBottom: '10px' }}
									sx={{
										width: {
											xs: '100%', sm: 'calc(33% - 10px)', md: 'calc(33% - 10px)'
										},
									}}
								>
									<InputLabel shrink={true} sx={{backgroundColor: 'white', px: 1}}>Choose Answer Size</InputLabel>
									<Select value={selectedAnswerSize} onChange={handleAnswerSizeChange} notched label="Choose LLM" >
										{Object.keys(answerSizeList).map((size) => (
											<MenuItem key={size} value={size}>
												{((size == defaultAnswerSize) ? "default: " : "") + size}
											</MenuItem>
										))}
									</Select>
								</FormControl>

								<FormControl
									style={{ marginTop: '10px', marginBottom: '10px' }}
									sx={{
										width: {
											xs: '100%', sm: 'calc(33% - 10px)', md: 'calc(33% - 10px)'
										},
									}}
									onFocus={handleFocus}
								>
			                    	<InputLabel shrink={true} sx={{backgroundColor: 'white', px: 1}}>Choose domain</InputLabel>
				                    <Select value={selectedDomain} onChange={handleDomainChange} notched label="Choose domain" >
				                        <MenuItem value="all">All domains</MenuItem>
				                        {domains.map((domainId) => (
				                            <MenuItem key={domainId} value={domainId}>
				                                {domainId}
				                            </MenuItem>
				                        ))}
				                    </Select>
				                </FormControl>
							</div>

				            <div className="adaptive-flex" style={{ margin: '20px 0' }}>
								<FormControl
									style={{ marginRight: '10px', marginTop: '10px', marginBottom: '10px' }}
									sx={{
										width: {
											xs: '100%', sm: 'calc(33% - 10px)', md: 'calc(33% - 10px)'
										},
									}}
									onFocus={handleFocus}
								>
									<InputLabel shrink={true} sx={{backgroundColor: 'white', px: 1}}>Document Relevance Weights</InputLabel>
									<Select value={JSON.stringify(documentRelevance)} onChange={handleDocumentRelevanceChange} notched label="Document Relevance Weights" >
										{relevanceWeightsOptions.map((option, index) => (
											<MenuItem key={index} value={JSON.stringify(option)}>
												 {((option.text == defualtRelevanceWeights.text && option.semantic == defualtRelevanceWeights.semantic) ? "default: " : "") + JSON.stringify(option)}
											</MenuItem>
										))}
									</Select>
								</FormControl>

								<FormControl
									style={{ marginRight: '10px', marginTop: '10px', marginBottom: '10px' }}
									sx={{
										width: {
											xs: '100%', sm: 'calc(33% - 10px)', md: 'calc(33% - 10px)'
										},
									}}
								>
									<InputLabel shrink={true} sx={{backgroundColor: 'white', px: 1}}>Chunk Relevance Weights</InputLabel>
									<Select value={JSON.stringify(chunkRelevance)} onChange={handleChunkRelevanceChange} notched label="Chunk Relevance Weights" >
										{relevanceWeightsOptions.map((option, index) => (
											<MenuItem key={index} value={JSON.stringify(option)}>
												{((option.text == 0.2 && option.semantic == 0.8) ? "default: " : "") + JSON.stringify(option)}
											</MenuItem>
										))}
									</Select>
								</FormControl>

								<FormControl
									style={{ marginTop: '10px', marginBottom: '10px' }}
									sx={{
										width: {
											xs: '100%', sm: 'calc(33% - 10px)', md: 'calc(33% - 10px)'
										},
									}}
								>
									<InputLabel shrink={true} sx={{backgroundColor: 'white', px: 1}}>Choose LLM</InputLabel>
									<Select value={selectedLLM} onChange={handleLLMChange} notched label="Choose LLM" >
										{LLMlist.map((model) => (
											<MenuItem key={model} value={model}>
												{((model == "anthropic:claude-3-5-sonnet-20240620") ? "default: " : "") + model}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</div>
                        </>
					)}

					{ questions.length > 0 &&
						<>
							<Typography variant="body2" style={{marginBottom: '10px'}}>
								Question examples:
							</Typography>

							<Grid container spacing={2} style={{ marginBottom: '20px' }}>
								{questions.map((question, index) => (
									<Grid item xs={12} md={6} key={index}>
										<Button
											variant="text"
											sx={{
												width: '100%',
												color: isLoading ? '#bbbbbb' : '#777777',
												backgroundColor: 'white',
												boxShadow: 'none',
												textTransform: 'none',
												justifyContent: 'flex-start',
												textAlign: 'left',
												'&:hover': {
													backgroundColor: '#f0f0f0',
													color: '#333333',
												},
												transition: 'background-color 0.3s',
											}}
											onFocus={handleFocus}
											disabled={isLoading}
											onClick={() => handleQuestionClick(question)}>
											{question}
										</Button>
									</Grid>
								))}
							</Grid>
						</>
					}

					<FormControl fullWidth onFocus={handleFocus} required>
	                    <TextField
	                        label="Insert your question"
	                        variant="outlined"
	                        value={question}
							sx={{ marginBottom: "20px" }}
	                        onChange={handleQuestionChange}
							InputProps={{
						        endAdornment: question && (
						          <InputAdornment position="end">
						            <IconButton onClick={() => {setQuestion('')}} edge="end">
						              <ClearIcon fontSize="small" />
						            </IconButton>
						          </InputAdornment>
						        ),
					      	}}
	                    />
	                </FormControl>

					<Button
						type="submit"
						variant="contained"
						color="primary"
						onFocus={handleFocus}
						disabled={isLoading}
						sx={{
							width: {
								xs: '100%', sm: '500px', md: '500px'
							},
						}}
					>
						{isLoading ? 'Sending question...' : 'Send question'}
					</Button>

            	</form>

				{error && <Alert severity="error" style={{ marginTop: '20px' }}>{error}</Alert>}

				{isLoading ? (
					<CircularProgress style={{ marginTop: '20px' }} />
				) : (
					answer &&
					<div>
						<Typography variant="body1" style={{ marginTop: '20px' }}>Answer:</Typography>
						<ReactMarkdown
							components={{
							    a: ({ node, ...props }) => (
							      <a {...props} target="_blank" rel="noopener noreferrer">
							        {props.children}
							      </a>
							    ),
							}}
						>
							{answer}
						</ReactMarkdown>
					</div>
				)}

			</CardContent>
		</Card>
	);
}
